import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlogPost } from '../../../_models/index';
import { PageService } from '../../../_services/index';
import { takeUntil } from 'rxjs/operators';
import { Subject} from 'rxjs';

@Component({
    selector: 'app-ecommerce-recent-story',
    templateUrl: './ecommerce-recent-story.component.html',
    styleUrls: ['./ecommerce-recent-story.component.scss'],
    standalone: false
})
export class EcommerceRecentStoryComponent implements OnInit, OnDestroy {
    posts: BlogPost[] = [];
    blogCarouselOptions = {
        items: 3,
        margin: 30,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1250: {
                items: 3,
                margin: 30
            }
        }
    };
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        private pageService: PageService) {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.getPosts();
    }

    getPosts() {
        this.pageService.getXBlogPosts(5).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.posts = x);
    }



}
