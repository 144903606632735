import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavService, Menu } from '../../../service/nav.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: false
})
export class MenuComponent implements OnInit, OnDestroy {
    faCalculator = faCalculator;
    public menuItems: Menu[];
    public openSide = false;
    public activeItem = 'home';
    public active = false;
    public activeChildItem = '';
    public overlay = false;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        public navServices: NavService) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit() {
        this.navServices.items.pipe(takeUntil(this.ngUnsubscribe)).subscribe(menuItems => {
            this.menuItems = menuItems;
        });
    }

    toggleSidebar() {
        this.openSide = !this.openSide;
    }


    closeOverlay() {
        this.openSide = false;
    }

    // For Active Main menu in Mobile View
    setActive(menuItem) {
        if (this.activeItem === menuItem) {
            this.activeItem = '';
        } else {
            this.activeItem = menuItem;
        }
    }

    isActive(item) {
        return this.activeItem === item;
    }

    // For Active Child Menu in Mobile View
    setChildActive(subMenu) {
        if (this.activeChildItem === subMenu) {
            this.activeChildItem = '';
        } else {
            this.activeChildItem = subMenu;
        }
    }

    ischildActive(subMenu) {
        return this.activeChildItem === subMenu;
    }


}
