<footer class="ecommerce-footer">
    <div class="container">
        <div class="row footer-theme partition-f">
            <div class="col-lg-4 col-md-6">
                <div class="footer-title footer-mobile-title">
                </div>
                <div class="footer-contant">
                    <div class="footer-logo"><img alt="" class="img-responsive" src="assets/images/solent/logo_footer.png"></div>
                    

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
            
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>About</h4>
                    </div>
                    <div class="footer-contant">
                        <p>Solent Wholesale are an independently owned and run flooring wholesaler. We
                            stock hundreds of ranges, most available for next day delivery across the south
                            of England.</p>

                        <div class="footer-social">
                        <ul>
                            <li><a href="https://www.facebook.com/SolentWholesale/">
                                <i aria-hidden="true" class="fa fa-facebook"></i></a>
                            </li>
                            <li><a href="https://twitter.com/solentwholesale">
                                <i aria-hidden="true" class="fa fa-twitter"></i></a>
                            </li>
                            <li><a href="https://www.instagram.com/solent_wholesale/">
                                <i aria-hidden="true" class="fa fa-instagram"></i></a>
                            </li>
                            <li><a href="/blog/feed"><i aria-hidden="true" class="fa fa-rss"></i></a></li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>Solent Wholesale Carpet Company Ltd</h4>
                    </div>
                    <div class="footer-contant">
                        <ul class="contact-list">
                            <li><i class="fa fa-map-marker"></i>Barnfield Drv, Chichester</li>
                            <li><i class="fa fa-map-marker"></i>W Sussex, PO19 6UX</li>
                            <li><i class="fa fa-phone"></i>01243 774623</li>
                            <li><i class="fa fa-envelope-o"></i>sales&#64;solentwholesale.com</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i aria-hidden="true" class="fa fa-copyright"></i> {{year}} Solent Wholesale Carpets</p>
                        <a [routerLink]="['/page/content/terms']">Terms Of Trading</a> |
                         <a [routerLink]="['/page/content/privacy']">Privacy Policy</a> | 
                         <a [routerLink]="['/page/content/care']">After Care Advice</a><br />
                         <p>Build: 2025-03-19-a</p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a href="javascript:void()"><img alt="" src="assets/images/icon/visa.png"></a>
                            </li>
                            <li>
                                <a href="javascript:void()"><img alt="" src="assets/images/icon/mastercard.png"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
