import { Component, OnInit } from '@angular/core';
import {
    faLaptopCode,
    faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons';


@Component({
    selector: 'app-ecommerce-links-tab',
    templateUrl: './ecommerce-links-tab.component.html',
    styleUrls: ['./ecommerce-links-tab.component.scss'],
    standalone: false
})

export class EcommerceLinksTabComponent implements OnInit {
    faLaptopCode = faLaptopCode;
    faShoppingBasket = faShoppingBasket;
    constructor () {
    }

    ngOnInit () {
    }


}
