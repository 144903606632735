import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageService } from '../../../_services/index';
import { ViewProduct } from '../../../_models/index';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-product-categories-slider',
    templateUrl: './product-categories-slider.component.html',
    styleUrls: ['./product-categories-slider.component.scss'],
    standalone: false
})
export class ProductCategorySliderComponent implements OnInit, OnDestroy {
    closeResult: string;
    public products: ViewProduct[] = null;
    public selectedSize: any = '';
    public counter = 1;
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        private pageService: PageService) { }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit () {
        this.pageService.getFeatureProducts(7).pipe(
            take(1),
            takeUntil(this.ngUnsubscribe))
            .subscribe(products => this.products = products);
    }

    productSliderOptions = {
        items: 7,
        margin: 30,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
        dots: false,
        responsive: {
            0: {
                items: 2
            },
            600: {
                items: 3
            },
            992: {
                items: 4
            },
            1000: {
                items: 5,
                margin: 30
            }
        }
    };

}
