import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    GenericResponse,
    AddProductFrom,
    ViewProduct,
    ColourType,
    ColourStockRef,
    BasicInfo,
    ProductSectors } from '../_models/index';
import { API_URL } from '../app.const';

@Injectable()
export class AdminProductService {
    private addProductURL = API_URL + '/admin/products/add_product';
    private updateProductURL = API_URL + '/admin/products/edit_product';
    private editProductDataURL = API_URL + '/admin/products/edit_product_data';
    private markProductClearanceURL = API_URL + '/admin/products/mark_product_clearance';
    private markProductHiddenURL = API_URL + '/admin/products/mark_product_hidden';
    private viewProductExtraInfoURL = API_URL + '/admin/products/view_extra_info';
    private removeMainImageURL = API_URL + '/admin/products/extras/remove_main_image';
    private getProductSectorsURL = API_URL + '/admin/products/list_sectors';
    private assignedProductSectorsURL = API_URL + '/admin/products/assigned_product_sectors';
    private saveNewColourTypeURL = API_URL + '/admin/products/add_colour_type';
    private editColourTypeURL = API_URL + '/admin/products/edit_colour_type';
    private updateColourNamesURL = API_URL + '/admin/products/extras/update_other_image_names';
    private removeOtherImageURL = API_URL + '/admin/products/extras/remove_other_image';
    private updateProductWYSIWYGURL = API_URL + '/admin/products/extras/update_wysiwyg';
    private updateDocumentNameURL = API_URL + '/admin/products/extras/update_document';
    private removeDocumentURL = API_URL + '/admin/products/extras/remove_document';
    private newStockRefURL = API_URL + '/admin/products/extras/new_stockref';
    private updateStockRefURL = API_URL + '/admin/products/extras/update_stockref';
    private basicImageInfoURL = API_URL + '/admin/products/extras/stockref_basic_info';
    private listImageStockrefsURL = API_URL + '/admin/products/extras/list_image_stockrefs';
    private removeImageStockrefURL = API_URL + '/admin/products/extras/remove_image_stockref_url';
    private getRelatedIdsURL = API_URL + '/admin/products/extras/get_related_ids';
    private addRelatedIdURL = API_URL + '/admin/products/extras/add_related_id';
    private removeRelatedIdURL = API_URL + '/admin/products/extras/remove_related_id';


    constructor (private http: HttpClient) {}

    saveNewProduct(product: AddProductFrom) {
        return this.http.post<GenericResponse>(this.addProductURL,
            JSON.stringify({ product }));
    }

    updateProduct(product: AddProductFrom) {
        return this.http.post<GenericResponse>(this.updateProductURL,
            JSON.stringify({ product }));
    }

    getProduct(id) {
        return this.http.post<AddProductFrom>(this.editProductDataURL,
            JSON.stringify({ id }));
    }

    getProductSectors() {
            return this.http.get<ProductSectors[]>(this.getProductSectorsURL);
    }

    getAssignedProductSectors(productID) {
        return this.http.post<ProductSectors[]>(this.assignedProductSectorsURL,
            JSON.stringify({ productID }));
    }

    getProductExtras(id) {
        return this.http.post<ViewProduct>(this.viewProductExtraInfoURL,
            JSON.stringify({ id }));
    }

    markProductClearance(id) {
        return this.http.post<GenericResponse>(this.markProductClearanceURL,
            JSON.stringify({ id }));
    }

    markProductHidden(id) {
        return this.http.post<GenericResponse>(this.markProductHiddenURL,
            JSON.stringify({ id }));
    }

    removeMainImage(id) {
        return this.http.post<GenericResponse>(this.removeMainImageURL,
            JSON.stringify({ id }));
    }

    updateColourNames(colourUpdate) {
        return this.http.post<GenericResponse>(this.updateColourNamesURL,
            JSON.stringify({ colourUpdate }));
    }

    newColourType(colourType: ColourType) {
        return this.http.post<GenericResponse>(this.saveNewColourTypeURL,
            JSON.stringify({ colourType }));
    }

    editColourType(colourType: ColourType) {
        return this.http.post<GenericResponse>(this.editColourTypeURL,
            JSON.stringify({ colourType }));
    }

    removeOtherImage(imageID, productID) {
        return this.http.post<GenericResponse>(this.removeOtherImageURL,
            JSON.stringify({ imageID, productID }));
    }

    updateProductWYSIWYG(wysiwygDATA, productID) {
        return this.http.post<GenericResponse>(this.updateProductWYSIWYGURL,
            JSON.stringify({ wysiwygDATA, productID }));
    }

    updateDocName(newName, documentID) {
        return this.http.post<GenericResponse>(this.updateDocumentNameURL,
            JSON.stringify({ newName, documentID }));
    }

    removeDocument(productID, documentID) {
        return this.http.post<GenericResponse>(this.removeDocumentURL,
            JSON.stringify({ productID, documentID }));
    }

    newStockRef(stockrefObj: ColourStockRef) {
        return this.http.post<GenericResponse>(this.newStockRefURL,
            JSON.stringify({ stockrefObj }));
    }

    updateStockRef(stockrefObj: ColourStockRef) {
        return this.http.post<GenericResponse>(this.updateStockRefURL,
            JSON.stringify({ stockrefObj }));
    }

    getBasicInfo(productID, imageID) {
        return this.http.post<BasicInfo>(this.basicImageInfoURL,
            JSON.stringify({ productID, imageID }));
    }

    listImageStockRefs(productID, imageID) {
        return this.http.post<ColourStockRef[]>(this.listImageStockrefsURL,
            JSON.stringify({ productID, imageID }));
    }

    removeImageStockref(imageID, stockrefID) {
        return this.http.post<GenericResponse>(this.removeImageStockrefURL,
            JSON.stringify({ imageID, stockrefID }));
    }

    getRelatedProductIDS(productID) {
        return this.http.post<[]>(this.getRelatedIdsURL,
            JSON.stringify({ productID }));
    }

    addRelatedProductID(parentID, relatedID) {
        return this.http.post<GenericResponse>(this.addRelatedIdURL,
            JSON.stringify({ parentID, relatedID }));
    }

    removeRelatedProductID(parentID, relatedID) {
        return this.http.post<GenericResponse>(this.removeRelatedIdURL,
            JSON.stringify({ parentID, relatedID }));
    }








}
