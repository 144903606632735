import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { faShoppingCart, faUserCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CartItem } from '../../../../_models/';
import { Observable } from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../_ngrx/index';
import { AuthenticationService } from '../../../../_services/index';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false
})
export class HeaderComponent implements OnInit, OnDestroy {
    faShoppingCart = faShoppingCart;
    faUserCircle = faUserCircle;
    faCircle = faCircle;
    expressCustomer = false;
    searchForm = new UntypedFormGroup({
        searchString: new UntypedFormControl(null),
    });


    basket$: Observable<CartItem[]> = this.store.select(fromRoot.selectBasket);

    public cartQty = 0;
    public url: any;
    private jwtHelper: JwtHelperService = new JwtHelperService();
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        private store: Store<fromRoot.State>,
        private router: Router,
        private authService: AuthenticationService
    ) {
        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.url = event.url;
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }


    ngOnInit () {
        this.basket$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(x => this.cartQty = x.length);
        this.expressCustomerTest();
    }

    expressCustomerTest() {
        this.expressCustomer = this.authService.localTokenTest();
    }

    onEnter() {
        const searchString = this.searchForm.get('searchString').value;
        this.searchForm.reset();
        this.router.navigate(['/products/list', searchString]);
    }

}
