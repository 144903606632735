// List of global constant variables
'use strict';
// Local Dev
//export const API_URL = 'http://0.0.0.0:6543';

// Docker local dev
//export const API_URL = 'http://localhost:8080';

// Production
export const API_URL = 'https://solent-cms-api.azurewebsites.net';
