import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { CartItem } from '../../../../_models/';
import * as fromRoot from '../../../../_ngrx/index';
import * as myActions from '../../../../_ngrx/actions';
import { AuthenticationService, SolentExpressService } from '../../../../_services/';
import {Store} from '@ngrx/store';
import {distinctUntilChanged, filter} from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent implements OnInit, OnDestroy {
    basket: CartItem[];
    basket$: Observable<CartItem[]> = this.store.select(fromRoot.selectBasket);
    public url: any;
    public year = new Date().getFullYear();
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    constructor (
        private router: Router,
        private store: Store<fromRoot.State>,
        private authService: AuthenticationService,
        private expressService: SolentExpressService) {
        this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.url = event.url;
            }
        });
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnInit () {
        // this.basket$.subscribe(basket => this.evalBasket(basket));
        this.basket$.pipe(filter(x => !!x),
            distinctUntilChanged((item1, item2) => {
                const x = ( item1 === item2);
                return x; }))
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(x => this.evalBasket(x));

    }

    evalBasket(basket: CartItem[]) {
        if (basket.length !== 0) {
            // Basket is not empty, send it to the API
            this.expressService.saveBasket(
                this.authService.returnCustomerNo(), basket)
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe(x => x);
        };

    }

}
