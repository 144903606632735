import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ecommerce-banner-slider',
    templateUrl: './ecommerce-banner-slider.component.html',
    styleUrls: ['./ecommerce-banner-slider.component.scss'],
    standalone: false
})
export class EcommerceBannerSliderComponent implements OnInit {

    banner = [
        {
            img: 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/homeslider/building-dark.jpg',
            title: 'Flooring Wholesale & Distribution',
            description: 'Established for over 50 years, we are the largest Independent Distributor of floor coverings on the South Coast, offering a swift service to the retail and contract trade only. We supply a wide range of floor coverings from our warehouse in Chichester, including: Carpets, Vinyl, Laminate, Carpet Tiles, Luxury Vinyl Tiles, Underlay and flooring accessories..',
            btn: 'Read Our History',
            link: '/page/about-us'
        },
        {
            img: 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/homeslider/huge_selection_dark.jpg',
            title: 'Huge Selection',
            description: 'We have hundreds of products in stock, available for next day delivery',
            btn: 'See our ranges',
            link: '/products/categories'
        },
        {
            img: 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/homeslider/advice_dark.jpg',
            title: 'Advice & Support',
            description: 'We have a great sales team, both on the road and in our office. If you need any advice, be it for product selection, or technical assistance on subfloor preperation, call Solent today for advice you can count on.',
            btn: 'Contact Us',
            link: '/page/contact'
        },
        {
            img: 'https://swc-website.s3-eu-west-1.amazonaws.com/static_images/homeslider/tradeonly_dark.jpg',
            title: 'Trade Only',
            description: 'We are suppliers strictly to the flooring trade only, ',
            btn: 'Open An Account',
            link: '/page/new-customer'
        }
    ];

    ecombannerOptions = {
        items: 1,
        nav: true,
        navClass: ['owl-prev', 'owl-next'],
        navText: ['<i class="icon-angle-left"></i>', '<i class="icon-angle-right"></i>'],
        dots: false,
        autoplay: false,
        slideSpeed: 390000,
        loop: true
    };

    offers = [
        {
            img: 'assets/images/inner-page/collection/3.jpg'
        },
        {
            img: 'assets/images/inner-page/collection/5.jpg'
        },
        {
            img: 'assets/images/inner-page/collection/6.jpg'
        }
    ];

    constructor () { }

    ngOnInit () { }


}
